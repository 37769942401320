@import "utils/mixins";
@import "base/colors";
@import "base/typography";

.section {
  background-color: $c-white;
  border-radius: 3px;
  overflow: hidden;

  @include tablet {
    border-radius: 3px;
  }

  .section__heading {
    overflow: hidden;
    align-items: center;
    padding-left: 20px;
    margin: 0;
    height: 50px;
    box-shadow: inset 0 -1px 0 0 $c-gray-border;
    background-color: $c-white;
    font-family: $font-ternary--semi-bold;
    font-size: $font-size-md1;
    letter-spacing: 1.4px;
    color: $c-black;

    @include mobile-sm {
      font-size: $font-size-md1;
      letter-spacing: 1.4px;
    }
  }

  .section__heading-sub {
    margin: 0;
    padding: 1.5em 1.1em 0;
    font-size: $font-size-md1;
    line-height: 1.5;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: $c-gray-medium-primary;
  }

  .section__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;

    .section__list {
      box-shadow: inset 0 -1px 0 0 $c-gray-border;
    }

    .section__pill {
      app-pill {
        display: flex;
        align-items: center;
        margin: 0 10px 10px 0;
        padding: 0 15px;
        height: 38px;
        border: 1px solid $c-gray-light-secondary;
        border-radius: 19.5px;
        font-size: $font-size-lg2;
      }
    }
  }
}
