@import 'utils/variables';

@font-face {
  font-family: Lato-Regular;
  src: url('/assets/fonts/Lato/Lato-Regular.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lato-Light;
  src: url('/assets/fonts/Lato/Lato-Light.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lato-Italic;
  src: url('/assets/fonts/Lato/Lato-RegularItalic.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lato-Bold;
  src: url('/assets/fonts/Lato/Lato-Bold.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('/assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('/assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('/assets/fonts/Montserrat/Montserrat-Medium.ttf');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SFUIText-Regular;
  font-weight: 400;
  src: url('/assets/fonts/SF-UI-Text-Regular.otf');
  font-display: swap;
  font-style: normal;
}
