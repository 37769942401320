$c-success: #4cd964;
$c-error: #db0000;
$c-accent-primary: #f2e046;
$c-accent-secondary: #d39b15;

$c-white: #fff;
$c-black: #000;
$c-gray-light-primary: #ececec;
$c-gray-light-secondary: #e4e4e4;
$c-gray-border: #f4f4f4;
$c-gray-medium-primary: #a3a3a3;
$c-gray-medium-secondary: #8d8d8d;
$c-gray-medium-show-more: #bdbdbd;
$c-gray-darkest-see-more: #999999;
$c-gray-dark-primary: #444444;
