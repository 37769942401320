@import 'base/colors';
@import 'base/typography';
@import 'utils/mixins';

.flex {
  display: flex;
}

.row {
  flex-direction: row;
  margin-right: 0;
  margin-left: 0;
}

.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-self-center {
  align-self: center;
}

.default-padding-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.divider {
  height: 15px;
  background-color: $c-gray-light-primary;

  @include tablet {
    height: 20px;
  }

  @include desktop {
    height: 20px;
  }
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.ta-center {
  text-align: center;
}

.padding-g {
  padding: 1.238em 1.238em 0.45em;
}

.padding-list-small {
  padding: 10px 15px;
}

.margin-b5 {
  margin-bottom: 5px;
}

.border-b {
  box-shadow: inset 0 -1px 0 0 $c-gray-light-primary;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.dib {
  display: inline-block;
}

.vam {
  vertical-align: middle;
}

.vas {
  vertical-align: sub;
}

.vab {
  vertical-align: baseline;
}

.db {
  display: block;
}

.color--gray {
  color: $c-gray-medium-primary;
}

.color--gray-d {
  color: $c-gray-medium-secondary;
}

.width-60 {
  width: 60px;
}

.width-100 {
  width: 100%;
}

.has-sibling {
  margin-right: 10px;
}

.app {
  width: 100%;
}

.marked {
  color: #d39b15;
}

.marked:hover {
  cursor: pointer;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #db0000;
}

hr {
  margin-left: 0;
  text-align: left;
  width: 100%;
}

.divider-line {
  width: calc(100% - 48px);
  border-top: 1px solid #d8d8d8;
  margin: 0 24px;

  @include desktop-plus {
    width: calc(100% - 140px);
    margin: 0 70px;
  }
}

.arrow-right {
  transform: rotate(-180deg);
}

.ngx-gallery-preview-img.ngx-gallery-center.animation {
  opacity: 1;
  transform: translateX(0);
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

.ngx-gallery-preview-img {
  position: relative;
}

.ngx-gallery-preview-text {
  position: absolute;
  bottom: 0;
  background: inherit !important;
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.arrow-prev-icon,
.arrow-next-icon {
  height: 32px;
  width: 32px;
}

.arrow-prev-icon {
  background: url('/assets/images/icons/arrow-left-circle.svg') center center no-repeat;
}

.arrow-next-icon {
  background: url('/assets/images/icons/arrow-right-circle.svg') center center no-repeat;
}

ngx-gallery-preview {
  .arrow-prev-icon {
    background: url('/assets/images/icons/arrow-left-gallery.svg') center center no-repeat;
  }

  .arrow-next-icon {
    background: url('/assets/images/icons/arrow-right-gallery.svg') center center no-repeat;
  }
}

.close-gallery {
  height: 27px;
  width: 27px;
  margin: 10px 10px 0 0;
  background: url('/assets/images/icons/close-gallery.svg') center center no-repeat;
}

// This is only a quick fix for prod
// TODO (Jovana, Mladen): Make universal mechanism for handling long descriptions
.ngx-gallery-preview-text {
  padding: 0 5% 0.5% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item {
  color: #282727;
  padding: 0;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  color: #282727;
  background: white;
}

.remove-scroll {
  overflow: hidden !important;
}

body.settings-page {
  background: #fafafa;
}

.link-hover {
  background: rgba(47, 47, 47, 0.07);
}

.button--hover {
  background: linear-gradient(0deg, rgba(47, 47, 47, 0.07), rgba(47, 47, 47, 0.07)), #f2e046;
}

.button-red--hover {
  background: linear-gradient(0deg, rgba(47, 47, 47, 0.07), rgba(47, 47, 47, 0.07)), #db0000;
}

.button--inverted {
  background-color: #fff;
  border: 2px solid #f2e046;
}

.button--inverted-hover {
  background: #f2e046 none;
}

.linkified,
.linkified:hover {
  text-decoration: none;
  color: $c-accent-secondary;
  word-break: break-all;
  font-size: 16px;
  line-height: 22px;
  font-family: $font-primary;
  font-weight: normal;
  font-style: normal;
}

.linkified:hover {
  cursor: pointer;
}

.ngx-gallery-image {
  border-radius: 0 !important;
}

.height-100 {
  height: 100%;
}

::-webkit-contacts-auto-fill-button {
  opacity: 0;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.featured-card__mark {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  line-height: 0.96;
}

.no-avatar-image {
  background-image: url('../../assets/images/pages/profile/no-avatar.png') !important;
  background-size: cover;
}

body.custom-pro-list-page {
  background: white !important;
}

.padding-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.margin-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-btm-30 {
  margin-bottom: 30px;
}

.truncated-section-divider {
  height: 15px;

  @include tablet {
    height: 30px;
  }

  @include desktop {
    height: 30px;
  }
}

// Quick fix, refactor this
.tooltip {
  opacity: 1 !important;
}

// Quick fix, refactor this
::ng-deep .tooltip-inner {
  position: absolute;
  left: -43px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 155px;
  height: 26px;
  padding: 5px;
  background: #ffffff;
  border-radius: 6px;
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}

// Quick fix, refactor this
::ng-deep .arrow::before {
  display: none;
}

.h6 {
  margin: 0;
  font-size: $font-size-sm2;
  line-height: 15px;
  font-family: $font-ternary--semi-bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #2f2f2f;
}

// TODO (Milan): Standardize across the app. Remove in-component scrollbar styles
// NOTE: A thing to note is that a scrollbar adds to the width of the page so elements
// will appear to have a larger padding on the right side where the scrollbar is because of
// this width. For this reason it seems better to use a simple narrow scrollbar.
.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dcdcdc;
  }
}
