@charset "UTF-8";

@import 'colors';
@import 'typography';
@import 'utils/mixins';

body {
  display: flex;
  background: $c-gray-light-primary;
  line-height: 1.5;
  font-size: $font-size-md1;
  font-family: $font-primary;
  font-weight: normal;
  color: black;
}

div {
  box-sizing: border-box;
}

div,
p,
section,
span h1,
h2,
h3,
a {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

p {
  margin: 0;
}

a,
a:hover {
  text-decoration: none;
  outline: none;
  color: inherit;
}

textarea {
  padding: 0;
  resize: none !important;
}

label {
  margin: 0;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.5 !important;
}

button.reset-button-styles {
  border: none;
  // NOTE: Safari adds padding to buttons by default
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
