@import 'base/colors';
@import 'base/normalize';
@import 'base/reset';
@import 'base/typography';

@import 'layout/grid';
@import 'layout/icons';
@import 'layout/section';
@import 'layout/gifs';
@import 'layout/placeholder';

@import 'themes/dark';

@import 'utils/auth-modal';
@import 'utils/book-modals';
@import 'utils/functions';
@import 'utils/helpers';
@import 'utils/mixins';
@import 'utils/modals';
@import 'utils/search-and-filters';
@import 'utils/variables';

@import 'shame';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~swiper/swiper-bundle';

/* -------------------------------------------------------------------------- */
/* user profile page */

.jamm-swiper-pagination-bullet {
  opacity: 1;
  background: #e0e0e0;
  border-radius: 100%;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 6px;
  &:last-child {
    margin-right: 0px;
  }
  &--active {
    background: #f2e046;
  }
}

.user-profile__wrapper {
  margin: auto;

  @include mobile-sm {
    margin-bottom: 124px;
  }

  @include mobile-md {
    margin-bottom: 285px;
  }

  @include tablet {
    margin-bottom: 213px;
    max-width: 540px;
  }

  @include desktop {
    margin-bottom: 119px;
  }

  .user-profile__container {
    @include tablet {
      padding: 20px 20px 0 20px;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px 20px 0 20px;
    }
  }
}

.user-profile__content {
  width: 100%;
  max-width: 660px;

  @include tablet-plus {
    min-width: 400px;
  }

  @include desktop-xl-plus {
    width: 660px;
  }
}

.user-profile__no-content {
  @include tablet-plus {
    padding: 30px 0 0 30px;
  }

  @include desktop-sm-plus {
    padding: 50px 0 0;
  }
}

html {
  overflow: visible;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  position: static;
}

html[class~='modal-opened'] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

html[class~='no-scroll'] {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.user-page-content__content {
  [role='tabpanel'] {
    height: 100%;
  }
}

// By default Ng Bootstrap makes this margin -1px so inner elements overflow by 1px if we don't set it to 0
.user-page-container .nav-tabs .nav-item {
  margin-bottom: 0;
}

// ng-select default styles override
ng-select {
  .ng-select-container {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    * {
      font-family: $font-primary !important;
      font-size: $font-size-lg2 !important;
      line-height: 26px !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
  ng-dropdown-panel {
    font-family: $font-primary !important;
    font-size: $font-size-lg2 !important;
    line-height: 26px !important;
    border-radius: 6px !important;
    .ng-option {
      border-radius: 6px !important;
      margin: 3px;
    }
    .ng-option.ng-option-marked {
      background-color: #e9e9e9 !important;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: white !important;
  }
}
