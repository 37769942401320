@import 'mixins';
@import 'utils/helpers';

.modal-window,
.media-modal-window,
.edit-modal-window,
.mentorship-modal-window,
.mentorship-session-rating-modal-window {
  overflow: scroll;
  display: block !important;
  justify-content: center;
  align-items: center;

  @include mobile-md-plus {
    display: flex !important;
  }

  .modal-dialog {
    @extend .flex;
    @extend .align-center;
    @extend .justify-center;

    top: 0;
    margin: auto;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    padding: 0;

    @include mobile-md-plus {
      width: 90%;
      height: auto;
      padding: 10px 0;
    }

    @include desktop-md-plus {
      width: 1000px;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    margin-top: 0;
    border: none;
    background: black;

    @include mobile-md-plus {
      height: auto;
    }

    @include ipad-sm-plus {
      flex-direction: row;
    }

    @include desktop-md-plus {
      width: 1000px;
    }
  }

  app-auth-controller-modal,
  app-book-modal,
  app-booking-sent-modal,
  app-media-modal,
  app-request-sent-modal,
  app-info-modal,
  app-info-modal-success,
  app-info-modal-thanks-for-contacting,
  app-install-app-modal,
  app-jammboard-gig-main-card-details,
  app-book-modal-pending-request,
  app-book-modal-add-payout,
  app-book-modal-pay-to-confirm,
  app-book-modal-confirmed,
  app-book-modal-complete,
  app-book-modal-contact-support,
  app-book-modal-rating,
  app-book-modal-payout-details,
  app-booking-services-modal,
  app-edit-profile-modal-basic,
  app-edit-modal,
  app-fast-booking-availability-modal,
  app-truncated-profile-modal-basic,
  app-mentorship-modal,
  app-payment-methods-modal-add-card,
  app-mentorship-modal,
  app-mentorship-session-rating-modal,
  app-notify-me-modal {
    @include ipad-sm-plus {
      flex-direction: row;
      width: 100%;
    }

    @include desktop-md-plus {
      width: 100%;
    }
  }
}

// NOTE: Apply this class to a modal host element with @HostBinding, check if the width styles above could be put in this class
.host-fill-height {
  height: 100%;
}

.modal-fill-screen {
  height: auto;
  min-height: 100%;

  @include mobile-md-plus {
    min-height: auto;
  }
}

.edit-modal-window {
  .modal-dialog {
    max-width: 720px;

    @include desktop-md-plus {
      width: 720px;
    }
  }
}

.mentorship-modal-window {
  .modal-content {
    border-radius: 0;

    @include mobile-md-plus {
      border-radius: 12px;
    }
  }

  .modal-dialog {
    width: 100%;

    @include mobile-md-plus {
      width: 90%;
    }

    @include ipad-plus {
      width: 685px;
    }

    @include desktop-plus {
      width: 785px;
    }

    @include desktop-xxl-plus {
      width: 1000px;
    }
  }
}

.fast-checkout-modal-window {
  .modal-dialog {
    @include ipad-plus {
      width: 660px !important;
    }
  }
}

.mentorship-modal-window {
  .modal-dialog {
    @include desktop-xxl-plus {
      width: 785px;
    }
  }
}

.mentorship-session-rating-modal-window {
  .modal-content {
    border-radius: 0;

    @include mobile-md-plus {
      border-radius: 12px;
    }
  }

  .modal-dialog {
    width: 100%;

    @include mobile-md-plus {
      width: 90%;
    }

    @include ipad-plus {
      width: 658px;
    }
  }
}

app-auth-controller-modal {
  overflow: hidden;
}

.media-modal-window {
  .modal-dialog {
    height: 100%;
  }
}

.media-modal-container {
  display: flex !important;
  align-items: center;
}

.media-modal {
  margin-top: -2px;
  margin-left: -1px;
  margin-right: -1px;
  width: 100%;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.5);

  @include mobile-sm {
    max-height: 465px;
  }

  iframe {
    border: none;
  }
}

.eye-closed,
.eye-opened {
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}

app-email-inbox-card {
  display: flex;
  justify-content: center;
}

// Info modals - success, warning, failed

.info-modal {
  width: 100%;
  height: auto;
  min-height: 100%;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  background: white;

  @include mobile-md-plus {
    min-height: auto;
    border-radius: 12px;
  }
}

.info-modal {
  .auth-modal__close {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 16px;
    width: 16px;
  }
}

.info-modal__header {
  flex-grow: 1;
  min-height: 250px;
  background-size: cover;

  @include mobile-md-plus {
    min-height: 280px;
    border-radius: 12px 12px 0 0;
  }

  background: url('/assets/images/components/modals/request-sent-header.svg');
}

.info-modal__content {
  padding: 20px 3% 85px 3%;
  max-width: 100%;

  @include mobile-md-plus {
    padding: 25px 3% 30px 3%;
    border-radius: 0 0 12px 12px;
    min-height: 384px;
    background: white;
  }

  @include ipad-plus {
    padding: 50px 3%;
  }
}

app-booking-sent-modal {
  .info-modal__content {
    @include mobile-md-plus {
      min-height: auto;
    }
  }

  .info-modal__description {
    @include ipad-plus {
      width: 70%;
    }
  }
}

.info-modal__title {
  margin: auto auto 24px;
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
}

.info-modal__heading {
  .info-modal__title {
    font-size: 22px;
  }
}

.info-modal__btn-container {
  margin-bottom: 10px;
  width: 295px;
  border-radius: 4px;

  @include mobile-md-plus {
    margin-bottom: 20px;
  }

  @include tablet-plus {
    margin-bottom: 0;
  }
}

.info-modal__description {
  margin-bottom: 20px;
  width: 80%;
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  @include mobile-md {
    width: 80%;
  }

  @include mobile-md-plus {
    margin-bottom: 35px;
    width: 75%;
  }
}

.info-modal__multiple-buttons {
  app-button {
    margin-right: 5px;
    margin-left: 5px;
  }
}

app-install-app-modal {
  .info-modal__title {
    font-size: 22px !important;

    @include ipad-sm-plus {
      margin-top: 34px;
      margin-bottom: 50px !important;
      font-size: 28px !important;
    }
  }

  app-apps-buttons-group {
    margin-bottom: 34px;

    @include ipad-sm-plus {
    }
  }
}

.info-modal-thanks-for-contacting {
  .info-modal__description {
    width: 436px !important;
    line-height: 22px;
  }
}

app-book-modal-payout-details {
  .request-sent-card {
    margin-bottom: 48px !important;
  }
}

.truncated-profile__rounded-corners-wrapper {
  z-index: 2;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  background-color: white;
}

.truncated-profile__content-container {
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: overlay;
  height: 100%;
  padding: 35px 24px 50px 24px;

  @include mobile-md-plus {
    border-radius: 12px;
    overflow-y: scroll;
    height: auto;
    max-height: 500px;
  }

  // Left and right paddings follow the seller card paddings
  @include ipad-plus {
    padding: 35px 45px 50px 45px;
    height: auto;
    max-height: 500px;
  }

  @include desktop-plus {
    height: auto;
    max-height: 500px;
    padding: 35px 50px 50px 50px;
  }

  @include desktop-xl-plus {
    height: auto;
    max-height: 660px;
  }

  @include desktop-xxl-plus {
    height: auto;
    max-height: 660px;
  }
}

.truncated-profile__content-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include mobile-md-plus {
    width: 22px;
  }
}

.truncated-profile__content-container::-webkit-scrollbar-track {
  margin: 35px 0;

  @include mobile-md-plus {
    margin: 7px 0;
  }
}

.truncated-profile__content-container::-webkit-scrollbar-thumb {
  border: solid 9px white;
  border-radius: 12px;
  background: #dcdcdc;
}

.payment-methods-modal {
  .book-modal__section {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.modal-content {
  @include mobile-md-plus {
    border-radius: 12px;
  }
}

.mentorship-modal::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.data-input-modal-window {
  overflow: scroll;
  display: block !important;
  justify-content: center;
  align-items: center;

  @include mobile-landscape-plus {
    display: flex !important;
  }

  .modal-dialog {
    @extend .flex;
    @extend .align-center;
    @extend .justify-center;

    top: 0;
    margin: auto;
    width: 100%;
    max-width: 640px;
    height: 100%;

    @include mobile-landscape-plus {
      height: auto;
      padding: 10px 0;
    }
  }

  .modal-content {
    border-radius: 0;
    border: none;
    width: 100%;
    height: 100%;

    @include mobile-landscape-plus {
      border-radius: 12px;
    }
  }
}

.data-input-modal {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  border-radius: 0;
  width: 100%;
  height: 100%;

  @include mobile-landscape-plus {
    border-radius: 12px;
  }

  .data-input-modal__header {
    position: relative;
    width: 100%;
    height: 154px;
    padding: 64px 37px 39px 27px;
    background-color: $c-accent-primary;

    .data-input-modal__header-close {
      position: absolute;
      top: 37px;
      right: 37px;
      background-color: transparent;

      img {
        width: 14px;
      }
    }

    .data-input-modal__header-title {
      margin-bottom: 8px;
      font-size: 28px;
      line-height: 26px;
      font-weight: 900;
    }

    p {
      font-size: $font-size-md1;
      line-height: 17px;
    }
  }

  .data-input-modal__content {
    padding: 0 32px 32px 32px;
    background-color: white;
  }
}
