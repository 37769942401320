@import 'base/colors';
@import 'base/typography';
@import 'mixins';

.auth-modal__info {
  position: relative;
  padding: 48px 30px 48px 30px;
  width: 100%;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px !important;
  letter-spacing: normal;
  color: black;

  &.auth-modal__mobile-scrollable {
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  @include ipad-sm-plus {
    padding: 48px 45px 48px 35px;
    width: 51%;
    font-size: 14px;
  }

  @include tablet-plus {
    padding: 48px 65px 48px 55px;
    width: 51%;
    font-size: 14px;
  }

  @include desktop-md-plus {
    font-size: 16px;
  }
}

.auth-modal__close-white {
  z-index: 3;
  display: inline;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;

  @include ipad-sm-plus {
    display: none;
  }
}

.auth-modal__close {
  display: inline;
  position: absolute;
  top: 18px;
  right: 24px;
  height: 16px;
  width: 16px;
}

.auth-modal__close:hover,
.auth-modal__close-white:hover {
  cursor: pointer;
}

.auth-modal__header {
  max-width: 405px;
  margin-bottom: 24px;
}

.auth-modal__heading {
  overflow: hidden;
  margin: 0;
  font-size: 24px;
  font-family: $font-primary--bold;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: black;

  @include ipad-sm-plus {
    font-size: 20px;
    line-height: normal;
  }

  @include desktop-md-plus {
    font-size: 28px;
    line-height: 1.36;
  }
}

.auth-modal__info-section {
  margin-top: 32px;
}

.auth-modal__subheading {
  margin-bottom: 8px;
  max-width: 382px;
  height: 15px;
  font-size: 12px;
  font-family: $font-ternary--semi-bold;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.5px;
  color: #282727;

  @include ipad-sm-plus {
    font-size: 10px;
  }

  @include desktop-md-plus {
    font-size: 12px;
  }
}

.auth-modal__input-section > div {
  margin-bottom: 8px;
}

.auth-modal__input-section {
  margin-top: 16px;

  div:last-of-type {
    margin-bottom: 0;
  }
}

.auth-modal__input,
.auth-modal__input:focus {
  border-radius: 0;
  padding-top: 26px;
  padding-left: 16px;
  padding-bottom: 8px;
  max-width: 100%;
  height: 60px;
  border: 1px solid #f2f2f2;
  box-shadow: none;
  background: #ffffff;
  font-size: 18px;
  font-family: $font-primary;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: black;

  @include ipad-sm-plus {
    height: 50px;
    font-size: 16px;
  }

  @include desktop-md-plus {
    height: 60px;
    font-size: 18px;
  }
}

.auth-modal__input:focus {
  border: 1px solid #f2f2f2;
}

.auth-modal__input.ng-invalid.ng-dirty {
  color: #000;
}

.auth-modal__input.ng-invalid.ng-dirty + .floating-label {
  color: #db0000;
}

.auth-modal__name,
.auth-modal__email {
  position: relative;
}

.auth-modal__input.link-sent {
  box-shadow: none;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  font-size: 18px;
  transition: 0.2s ease all;
  font-family: $font-primary;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgba(40, 39, 39, 0.5);
  line-height: 26px;

  @include ipad-sm-plus {
    top: 12px;
    font-size: 16px;
  }

  @include desktop-md-plus {
    top: 18px;
    font-size: 18px;
  }
}

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  bottom: 10px;
  left: 16px;
  opacity: 1;
  top: 8px;
  font-size: 12px;
  font-family: $font-ternary--semi-bold;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 15px;
  color: #2f2f2f;

  @include ipad-sm-plus {
    top: 6px;
    font-size: 10px;
  }

  @include desktop-md-plus {
    top: 8px;
    font-size: 12px;
  }
}

.auth-modal__send-btn-container {
  margin-top: 8px;
}

.auth-modal__send-btn {
  max-width: 100%;
  height: 50px;
  border-radius: 0;
  background-color: #f2e046;
  font-family: $font-ternary--semi-bold;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  text-align: center;
  color: black;

  @include ipad-sm-plus {
    padding: 10px;
    height: 40px;
  }

  @include ipad {
    padding: 5px;
    font-size: 12px;
  }

  @include desktop-md-plus {
    height: 50px;
    font-size: 14px;
  }
}

.auth-modal__send-btn.link-sent {
  background-color: #e4e4e4;
}

.auth-modal__text-section {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 23px;
}

.auth-modal__disclaimer {
  margin-top: 8px;
  max-width: 390px;
  height: 30px;
  opacity: 0.7;
  font-size: 12px;
  font-family: $font-primary;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  color: #000000;

  @include ipad-sm-plus {
    font-size: 10px;
  }

  @include desktop-md-plus {
    font-size: 12px;
  }
}

.auth-modal__disclaimer--link {
  font-weight: bold;
  color: #d39b15;
}

.auth-modal__disclaimer--link:hover {
  cursor: pointer;
  color: #d39b15;
}

.auth-modal__forgot-pass-container {
  margin-top: 25px;
}
