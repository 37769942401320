$mobile-sm-width: 320px;
$android-sm-width: 360px;
$iphone-11-pro-max-width: 414px;
$mobile-md-width: 425px;
$mobile-landscape-width: 640px;
$ipad-sm-width: 670px;
$ipad-width: 768px;
$ipad-pro-width: 834px;
$ipad-pro-11-vertical-width: 834px;
$tablet-width: 904px;
$desktop-sm-width: 900px;
$desktop-md-width: 1000px;

$desktop-width: 1024px;
$macbook-width: 1152px;
$ipad-pro-11-width: 1194px;
$imac-width: 1280px;
$desktop-xl-width: 1400px;
$desktop-standard-width: 1440px;
$desktop-xxl-width: 1920px;

$font-primary: Lato-Regular, sans-serif;
$font-primary--light: Lato-Light, sans-serif;
$font-primary--italic: Lato-Italic, sans-serif;
$font-primary--bold: Lato-Bold, sans-serif;
$font-secondary: SFUIText-Regular, sans-serif;
$font-ternary: Montserrat-Regular, sans-serif;
$font-ternary--medium: Montserrat-Medium, sans-serif;
$font-ternary--semi-bold: Montserrat-SemiBold, sans-serif;

$font-size-sm1: 11px;
$font-size-sm2: 12px;

$font-size-md1: 14px;
$font-size-md2: 15px;

$font-size-lg1: 16px;
$font-size-lg2: 17px;

$font-size-xl2: 23px;
$font-size-xl2: 24px;
