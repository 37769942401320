@import 'base/colors';
@import 'base/typography';
@import 'utils/mixins';

.book-modal__info {
  height: 100%;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;

  @include mobile-md-plus {
    min-height: auto;
  }

  .auth-modal__close {
    position: sticky;
    top: 18px;
    left: calc(100% - 30px);
  }
}

.book-modal__info::-webkit-scrollbar {
  width: 5px;
  height: 240px;
}

.book-modal__info::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #dcdcdc;
}

.book-modal__steps {
  flex-wrap: wrap;
  margin-top: 18px;
}

.book-modal__first-step {
  font-size: $font-size-sm2;
  font-family: $font-primary;
  white-space: nowrap;
}

.book-modal__active-step {
  font-size: $font-size-sm2;
  font-family: $font-primary;
  white-space: nowrap;
}

.book-modal__active-step::before {
  display: inline-block;
  margin: 0 8px;
  width: 8px;
  height: 10px;
  content: '';
  background: url('/assets/images/icons/arrow-right-dark.svg') no-repeat;
  background-size: contain;
}

.book-modal__inactive-step {
  font-size: $font-size-sm2;
  font-family: $font-primary;
  white-space: nowrap;
  color: $c-gray-medium-primary;
}

.book-modal__inactive-step::before {
  display: inline-block;
  margin: 0 8px;
  width: 8px;
  height: 10px;
  content: '';
  background: url('/assets/images/icons/arrow-right-light.svg') no-repeat;
  background-size: contain;
}

.book-modal__section {
  margin-left: 20px;
  margin-right: 20px;

  @include mobile-md {
    margin-left: 36px;
    margin-right: 36px;
  }

  @include mobile-md-plus {
    margin-left: 50px;
    margin-right: 45px;
  }
}

hr {
  margin-top: 22px !important;
  margin-bottom: 16px !important;
}

.book-modal__header {
  margin-top: 18px;
  margin-bottom: 30px;
  max-width: 405px;
}

.book-modal__headline {
  margin-bottom: 30px;
  font-family: $font-ternary--semi-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.5px;
  color: #282727;
}

.book-modal__label {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #282727;
}

.book-modal__avatar {
  margin-bottom: 5px;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.book-modal__avatar-info {
  margin-left: 8px;
}

.book-modal__avatar-info-name {
  margin-bottom: 3px;
}

.book-modal__name {
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  vertical-align: bottom;
}

.book-modal__featured {
  margin-left: 7px;
  margin-bottom: 1px;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  line-height: 0.96;
}

.book-modal__city {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #282727;
  mix-blend-mode: normal;
  opacity: 0.6;
}

.book-modal__input-text {
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  opacity: 1 !important;
  overflow: auto;
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #282727;
  mix-blend-mode: normal;
  word-break: break-word;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.book-modal__input-text::-webkit-scrollbar {
  width: 0 !important;
}

.book-modal__input-text.ng-pristine,
.book-modal__input-text.ng-invalid {
  opacity: 0.5;
}

.book-modal__booking-date-label {
  opacity: 0.35 !important;
}

.book-modal__gig-title {
  min-height: 40px;
  max-height: 120px;
  overflow: auto;

  @include desktop {
    min-height: 26px;
  }
}

.book-modal__booking-date-container {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.book-modal__booking-date-input {
  position: relative;
  justify-content: space-between;
  width: auto;
  cursor: pointer;
}

.book-modal__payment-offer-container::before {
  content: '$';
  display: inline-block;
  vertical-align: middle;
}

.book-modal__payment-offer {
  width: 95%;
  vertical-align: middle;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.book-modal__disclaimer {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5 !important;
}

.book-modal__send-btn-container {
  z-index: 5;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 36px 20px;
  border-radius: 4px;
  width: 100%;
  background: white;

  @include mobile-md-plus {
    padding-left: 50px;
    padding-right: 45px;
  }
}

.book-modal__send-btn-container.book-modal-fast-checkout__sent-btn-container {
  position: relative;
}

app-book-modal-rating {
  .request-sent-card {
    margin: 0 0 36px 0 !important;
  }
}

app-rate-experience-group {
  .rate-experience-group__container {
    margin-bottom: 32px;
  }
}

app-book-modal-pay-to-confirm {
  .request-sent-card {
    margin: 0 0 44px 0 !important;
  }

  .book-modal__headline {
    margin-bottom: 18px !important;
  }
}

app-book-modal-payout-details {
  .request-sent-card {
    margin: 0 !important;
  }

  .book-modal__header {
    margin-bottom: 24px !important;
  }

  .book-modal__headline {
    margin-bottom: 16px !important;
  }
}
