@import 'variables';

@mixin mobile-sm {
  @media (max-width: #{$mobile-sm-width}) {
    @content;
  }
}

@mixin mobile-sm-plus {
  @media (min-width: #{$mobile-sm-width}) {
    @content;
  }
}

@mixin android-sm-plus {
  @media (min-width: #{$android-sm-width + 1px}) {
    @content;
  }
}

@mixin mobile-md {
  @media (min-width: #{$mobile-sm-width + 1px}) and (max-width: #{$mobile-md-width}) {
    @content;
  }
}

@mixin mobile-md-plus {
  @media (min-width: #{$mobile-md-width}) {
    @content;
  }
}

@mixin mobile-landscape-plus {
  @media (min-width: #{$mobile-landscape-width + 1px}) {
    @content;
  }
}

// TODO(Jovana, Milan): Remove this minus logic
@mixin mobile-md-minus {
  @media (max-width: #{$mobile-md-width}) {
    @content;
  }
}

@mixin mobile-md-to-ipad {
  @media (min-width: #{$mobile-md-width + 1px}) and (max-width: $ipad-sm-width) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: #{$mobile-md-width + 1px}) and (max-width: #{$ipad-width}) {
    @content;
  }
}

@mixin ipad-sm-plus {
  @media (min-width: $ipad-sm-width) {
    @content;
  }
}

@mixin ipad-plus {
  @media (min-width: #{$ipad-width}) {
    @content;
  }
}

@mixin ipad-pro-plus {
  @media (min-width: #{$ipad-pro-width}) {
    @content;
  }
}

// TODO(Jovana, Milan): Remove this minus
@mixin tablet-minus {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-md-width + 1px}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-plus {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}

// TODO(Jovana, Milan): Remove this minus logic
@mixin desktop-minus {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-sm-plus {
  @media (min-width: #{$desktop-sm-width}) {
    @content;
  }
}

@mixin desktop-md-plus {
  @media (min-width: #{$desktop-md-width}) {
    @content;
  }
}

@mixin desktop-plus {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin imac-plus {
  @media (min-width: #{$imac-width}) {
    @content;
  }
}

@mixin desktop-xl-plus {
  @media (min-width: #{$desktop-xl-width}) {
    @content;
  }
}

@mixin desktop-xxl-plus {
  @media (min-width: #{$desktop-xxl-width}) {
    @content;
  }
}

@mixin desktop-standard-plus {
  @media (min-width: #{$desktop-standard-width}) {
    @content;
  }
}

@mixin macbook-plus {
  @media (min-width: #{$macbook-width}) {
    @content;
  }
}

@mixin ipad-pro-11-plus {
  @media (min-width: #{$ipad-pro-11-width}) {
    @content;
  }
}

@mixin ipad-pro-11-vertical-plus {
  @media (min-width: #{$ipad-pro-11-vertical-width}) {
    @content;
  }
}

@mixin iphone-11-pro-max-plus {
  @media (min-width: #{$iphone-11-pro-max-width}) {
    @content;
  }
}
