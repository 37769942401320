.icon {
  height: 14px;
  width: auto;
  margin-right: 2px;

  .section__text & {
    height: 16px;
  }
}
.icon--youtube,
.icon--soundcloud {
  &.hidden {
    display: none;
  }
}
