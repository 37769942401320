@import 'utils/variables';
@import 'base/colors';

.search__accordion-override-style {
  ngb-accordion .card {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0 !important;
  }

  ngb-accordion.isFirst .card {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  ngb-accordion .card-header {
    padding: 0;
    background: white;
  }

  ngb-accordion .card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 27px 21px 27px 16px;
    font-size: 15px;
    line-height: 22px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    color: #282727;

    @include ipad-plus {
      gap: 35px;
      padding: 27px 36px;
      line-height: 16px;
    }
  }

  ngb-accordion .card-header button:focus {
    box-shadow: none;
  }

  ngb-accordion .card-header button h6 {
    margin-bottom: 0;
    font-size: $font-size-sm2;
    line-height: 16px;
    font-family: $font-ternary;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  ngb-accordion .card-body {
    padding: 0;
  }

  ngb-accordion .card-body-content {
    padding: 26px 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-primary;
    font-weight: normal;
    text-align: left;
    letter-spacing: 0.33px;
    color: #282727;

    @include ipad-plus {
      padding: 26px 36px;
    }
  }
}

.accordion__show-more {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.main-search__input-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  border-radius: 20px;
  width: 100%;
  max-width: 340px;
  height: 40px;
  padding: 12px;
  background-color: #f2f2f2;

  img {
    margin-right: 5px;
    height: 12px;
    // NOTE: Convert svg color to target color #858585
    filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(270deg) brightness(98%) contrast(92%);
  }

  input {
    border: none;
    width: 100%;
    padding: 0;
    background-color: transparent;
    font-size: $font-size-lg1;
    line-height: 18px;
    font-family: $font-primary;
    color: #282727;

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      opacity: 1 !important;
      color: #858585;
    }

    &::-moz-placeholder {
      opacity: 1 !important;
      color: #858585;
    }

    &:-ms-input-placeholder {
      opacity: 1 !important;
      color: #858585;
    }

    &:-moz-placeholder {
      opacity: 1 !important;
      color: #858585;
    }
  }
}

.filter-section__pill {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 14.5px;
  height: 30px;
  padding: 6px 14px;
  font-size: $font-size-sm2;
  font-family: $font-primary;
  color: $c-black;

  // NOTE: In some cases the pill is a button element and resetting the button styles on it
  // overrides the padding to 0 so we need higher specificity here for padding in that case
  &.reset-button-styles {
    padding: 6px 14px;
  }

  &.pill-selected {
    background-color: transparent;
    border: 1px solid $c-gray-medium-secondary;
  }

  // NOTE: If close icon is pressent, it should be a button with additional 'reset-button-styles' class
  button {
    background-color: transparent;

    img {
      margin-right: 10px;
      height: 10px;
      // NOTE: Convert svg color (this assumes the original color of svg is black #000000) to target color #282727
      filter: invert(6%) sepia(1%) saturate(1641%) hue-rotate(314deg) brightness(123%) contrast(81%);
    }
  }
}
